import React from 'react';
import './App.css';
import {BrowserRouter, Link, Route} from "react-router-dom";
import Authenticator from "./authenticated/Authenticator";
import InfoComponent from './infos/InfoComponent'
import {Button, Container} from "react-bootstrap";
import DevAuthenticator from "./authenticated/Dev";
import {ErrorComponent} from "./errors";
import PaymentReturnComponent from "./authenticated/payment/PaymentReturnComponent.js";
import RegisterPage from "./register/RegisterPage";

function Lobby() {
    return (<div>
        <Container>
            <Link to="/tour">
                <Button>
                    Présentation de la Vie Associative
                </Button>
            </Link>
        </Container>
    </div>)
}

function App() {
    window.localStorage.removeItem('psw');
    return (
        <div className="App">
            <BrowserRouter>
                <DevAuthenticator/>
                <div id="main-app">
                    <Route path="/registration" component={RegisterPage}/>
                    <Route path="/authenticated" component={Authenticator}/>
                    <Route path="/tour" component={InfoComponent}/>
                    <Route exact path="/" component={Lobby}/>
                </div>
                <ErrorComponent/>
            </BrowserRouter>
        </div>
    );
}

export default App;
