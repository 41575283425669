import React, {useState} from "react";
import {Alert, Container} from "react-bootstrap";
import '../infos/landing.css'
import axios from "axios";
import {apiUrl} from "../env";
import ProgressBar from "react-bootstrap/ProgressBar";
import {Link} from "react-router-dom";
import {handleAxiosError} from "../errors";
import RegisterForm from "./RegisterForm";

export default function RegisterPage() {
    const [registred, setRegistered] = useState(false);
    const [loading, setLoading] = useState(false);

    window.httpService = axios.create({
        baseURL: apiUrl,
    });

    function onRegistration(registration) {
        setLoading(true);
        window.localStorage.setItem("email", registration.email);
        window.httpService.post('/customer/registration/', registration)
            .then((res) => {
                setRegistered(true);
            })
            .catch((err) => {
                handleAxiosError(err);
                setLoading(false);
            });
    }

    return (<div>
        <div className="bgparent">
            <div className="holibg"/>
            <div className="holifg">
                <Container>
                    <div className="">
                        <h2 className="titleText">Inscription VA</h2>
                        <div className="landing-logos frosted main-landing-box">
                            <ProgressBar animated now="50" hidden={!loading}/>

                            {!registred ?
                                <div>
                                    <p>
                                        <b>Déjà enregistré ?</b> <Link to="/authenticated/biz"><span className="text-info">Connectez-vous pour continuer l'inscription</span></Link>
                                    </p>

                                    <RegisterForm member={{}} onSubmitted={onRegistration}
                                                  editAllowed={!loading}/>

                                </div>
                                :
                                <Alert variant="info">
                                    <p>Vous allez recevoir un mail pour vérifier votre adresse e-mail. Il vous faudra
                                        cliquer sur le lien (il commence par https://sso.asso-insa-lyon.fr).
                                        Le processus se
                                        déroulera
                                        sur un autre site, le serveur d'authentification centralisée Asso-insa-lyon.</p>
                                    <p>Vous pourrez alors poursuivre l'inscription</p>
                                    <p><b>N'oubliez pas de vérifier vos mails indésirables, ou spams !</b> Les mails de
                                        vérification
                                        arriveront probablement dedans.</p>
                                </Alert>
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    </div>)
}
