import React from "react";
import {Button, Card} from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import {MemberForm2} from "./MemberForm";
import {Link} from "react-router-dom";
import {handleAxiosError} from "../../errors";

function categoryToString(category) {
    switch (category) {
        case "student":
            return "Étudiant";
        case "worker":
            return "Employé INSA";
        default:
            return "Autres";
    }
}

/**
 *
 * @param props
 * @param props.member
 */
function ShowMember(props) {
    return (<div>
        <div>Date de naissance: <strong>{props.member.birthday}</strong></div>
        <div>Sexe: <strong>{props.member.gender === 'M' ? "Masculin" : "Féminin"}</strong></div>
        {props.member.category === "student" && props.member.student_profile !== null ? <div>
            <strong>{props.member.student_profile.study_year} {props.member.student_profile.department}</strong>
        </div> : categoryToString(props.member.category)}
        {props.member.has_valid_membership !== undefined ? (
            props.member.has_valid_membership ? <strong>Vous avez payé votre adhésion pour cette année</strong> :
                <strong>Vous n'avez pas adhéré à la vie associative cette année</strong>
        ) : ""}
    </div>)
}

class MemberComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member: props.member,
            editAllowed: true, //on voit le formulaire
            loading: false,
            progress: 10,
            editExisting: props.member === null,
        }
    }

    animateProgress() {
        setTimeout(() => {
            this.setState({progress: this.state.progress + 10});
            setTimeout(() => {
                this.setState({progress: this.state.progress + 5});
                setTimeout(() => {
                    this.setState({progress: this.state.progress + 5})
                }, 1000)
            }, 1000)
        }, 2000);
        this.setState({editAllowed: false, loading: true, progress: 5});
    }

    stopProgress() {
        setTimeout(() => {
            this.setState({loading: false, progress: 100});
            window.scroll(0, -9000);
        }, 500)
    }

    createMember(member) {
        if (this.props.account) {
            member['first_name'] = this.props.account.firstName;
            member['last_name'] = this.props.account.lastName;
            member['email'] = this.props.account.email;
        } else {
            member['first_name'] = ";";
            member['last_name'] = "lkj";
            member['email'] = "automated.test@yopmail.com";
        }
        console.log("avant envoi", member);

        this.animateProgress();

        window.httpService.post('/customer/member/', member).then(value => {
            console.log(value);
            this.setState({member: value.data});
            this.stopProgress()
        }).catch(error => {
            console.log(error);
            this.setState({editAllowed: true});
            this.stopProgress();
            handleAxiosError(error);
        })
    }

    updateMember(member) {
        console.log(member.id);
        window.httpService.patch('/customer/member/' + member.id + '/', member).then(value => {
            console.log(value);
            this.stopProgress();
            this.setState({member: value.data, editExisting: false})
        }).catch(error => {
            console.log(error);
            this.setState({editAllowed: true});
            this.stopProgress();
            handleAxiosError(error);
        });
        this.animateProgress();
    }

    onFormSubmit(member) {
        if (this.state.member !== null) {
            member.id = this.state.member.id;
            this.updateMember(member);
        } else {
            this.createMember(member);
        }
    }

    canGoToWei() {
        if (this.state.member !== null) {
            if (this.state.member.category === 'student') {
                return this.state.member.student_profile.school === 'INSA' && this.state.member.student_profile.study_year === '1A'
            }
        }
        return false;
    }

    render() {
        return (<div>
            {this.state.member !== null && this.state.member !== undefined ?
                <Link to="/authenticated/joinva">
                    <Button variant="success">Continuer l'adhésion</Button>
                </Link> : ""}
            <br/>&nbsp;
            <Card className="bg-secondary">
                <Card.Header>Formulaire adhérent</Card.Header>
                <Card.Body>
                    <ProgressBar animated now={this.state.progress} hidden={!this.state.loading}/>
                    {this.state.member != null && !this.state.editExisting ? <div>
                        <ShowMember member={this.state.member}/>
                        <Button onClick={() => {
                            this.setState({editExisting: true, editAllowed: true})
                        }}>Modifier</Button>
                    </div> : ""}
                    {this.state.editExisting ? (
                        <MemberForm2 member={this.state.member ?? {}}
                                     onSubmitted={(member) => this.onFormSubmit(member)}
                                     editAllowed={this.state.editAllowed}/>
                    ) : ""}
                </Card.Body>
            </Card>

        </div>);
    }
}

export default MemberComponent
