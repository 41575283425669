import React from 'react';
import {Button, Container, Modal, Spinner} from 'react-bootstrap';
import './JoinVA.css';
import {handleAxiosError} from "../errors";
import PaymentComponent from "./payment/PaymentComponent";
import {getResponseCodeDescription} from "./payment/codes";

class JoinVA extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            toPayMembershipTypeId: null,
            toPayMembership: null,
            paymentReturnCode: null,
            availableMemberships: [],
            userMemberships: [],
        };
    }

    componentDidMount(){
        // Set the paymentReturnCode from the code GET parameter
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        this.setState({paymentReturnCode: code});
        // Clear the code from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        // Fetch available memberships at /customer/joinva/
        this.props.httpService.get('/customer/joinva/').then(response => {
            console.log('available memberships for user:', response.data.available_memberships);
            this.setState({availableMemberships: response.data.available_memberships});
        }).catch(error => {
            handleAxiosError(error);
        })
        // Fetch current user's memberships at /customer/member/
        this.props.httpService.get('/customer/member/').then(response => {
            console.log('user memberships:', response.data[0].memberships);
            this.setState({userMemberships: response.data[0].memberships});
        }).catch(error => {
            handleAxiosError(error);
        })
    }

    startUp2PayPayment = (membership) => {
        this.setState({toPayMembershipTypeId: membership.id});

        console.log('Starting payment for membership:', membership);
        this.props.httpService.post('/customer/joinva/', {
            payment_method: 4,
            membership_type_id: membership.id,
        })
            .then(response => {
                console.log('Payment response:', response.data);
                this.setState({toPayMembership: response.data});
            })
            .catch(error => {
                this.setState({errorMessage: 'Erreur lors de l\'inscription', successMessage: ''});
            });
    }

    up2payRedirect = () => {
        setTimeout(() => {
            document.getElementById('redirectForm').submit();
        }, 300);
    };

    render(){
        const {toPayMembershipTypeId, toPayMembership, paymentReturnCode} = this.state;

        return (
            <div className="bgparent">
                <div className="holibg"/>
                <div className="holifg">
                    <Modal show={toPayMembershipTypeId != null}
                           onHide={() => this.setState({toPayMembershipTypeId: null})} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title><h5>Paiement !</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {toPayMembership != null ?
                                <PaymentComponent membership={toPayMembership}
                                                  errorHandler={handleAxiosError}
                                                  child={<Button variant="secondary" onClick={() => {
                                                      this.setState({toPayMembershipTypeId: null});
                                                  }}>Annuler</Button>
                                                  }/>
                                :
                                <div><Spinner animation="border"/> Initialisation...</div>
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal show={paymentReturnCode} onHide={() => this.setState({paymentReturnCode: null})} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {paymentReturnCode === "00000" ? "Paiement réussi !" : "Paiement échoué !"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {paymentReturnCode === "00000" ?
                                <p>
                                    Le paiement en ligne a réussi ! <br/>
                                    Vous pouvez passer au BdE pour récupérer votre carte VA.
                                </p>
                                :
                                <p>
                                    Le paiement en ligne a échoué pour la raison suivante : <b>{getResponseCodeDescription(paymentReturnCode)}</b>
                                </p>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({paymentReturnCode: null})}>
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Container fluid style={{padding: 0, margin: 0}}>
                        <div className="vacontainer">
                            <h2 className="titleText">Adhésions disponibles</h2>
                            <div className="joinva-logos frosted main-landing-box">
                                {
                                    this.state.availableMemberships.map((membership, index) => {
                                        return (
                                            <div className="box" key={index}>
                                                <img src={"/" + membership.name + ".png"} alt="Card"/>
                                                <p>{
                                                    // If a line starts with *, enclose it in a <span> tag
                                                    membership.description.split('\n').map((line, index) => {
                                                        return line.startsWith('*') ?
                                                            <span key={index}
                                                                  style={{fontSize: '0.8em'}}><br/>{line}<br/></span> :
                                                            <span key={index}>{line}<br/></span>;
                                                    })
                                                }</p>
                                                <p className="price">Prix : {membership.price_ttc} €</p>
                                                {
                                                    this.state.userMemberships.some(userMembership => userMembership.membership === membership.name && userMembership.valid) ?
                                                        <Button disabled={true}>Vous possédez cette adhésion</Button> :
                                                        <Button onClick={() => this.startUp2PayPayment(membership)}>Payer</Button>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default JoinVA;
