import React from 'react';
import * as Keycloak from "keycloak-js";
import {Link, Route} from "react-router-dom";
import ProfileComponent from "./Profile";
import Navbar from "react-bootstrap/Navbar";
import {Button, Container, Nav, Spinner} from "react-bootstrap";
import {apiUrl, authUrl, clientId, realm} from "../env";
import axios from "axios";
import './Authenticator.css'
import BizuthFlow from "../bizuth/BizuthFlow";
import JoinVA from "./va2a";
import PaymentReturnComponent from "./payment/PaymentReturnComponent";

//https://scalac.io/user-authentication-keycloak-1/ Jakub Mikulski 2020

function Home() {
    window.httpService.get('/customer/joinva/').then((res) => {
        console.log(res.data);
    });
    return <div>
        <br/>
        <Link to="member">
            <Button id="completeMember">Compléter ma fiche adhérent</Button>
        </Link>
        <br/>
        <h3>Connexion réussie !</h3>
        <p>Vous êtes connecté grâce au serveur d'authentification centralisée, Keycloak.</p>
    </div>
}

const allYourMessagesAreBelongToUs = [
    "powering up Atlantis ...",
    "changement du directeur...",
    "dialling the Destiny ...",
    "consolation des serveurs ...",
    "executing order 66 ...",
    "Hacking to the Gate; ...",
    "Link Start! ..."
];

class Authenticator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            keycloak: null,
            authenticated: false,
            httpService: null
        }
    }

    componentDidMount() {
        if (localStorage.getItem("basictoken") !== null) {
            console.log("using basic auth");
            window.httpService = axios.create({
                baseURL: apiUrl,
                headers: {
                    Authorization: "Basic " + localStorage.getItem("basictoken")
                }
            });
            this.setState({keycloak: {}, authenticated: true})

        } else {
            const keycloak = Keycloak({
                "realm": realm,
                "auth-server-url": authUrl,
                "ssl-required": "all",
                "resource": clientId,
                "public-client": true,
                "confidential-port": 0,
                clientId: clientId,
                url: authUrl,
                "enable-cors": true,
                flow: 'implicit',
                enableLogging: true
            });
            keycloak.init({
                onLoad: 'login-required', enableLogging: true,
            }).then(authenticated => {
                let httpService = axios.create({
                    baseURL: apiUrl,
                    headers: {
                        Authorization: "Bearer " + keycloak.token
                    }
                });
                window.httpService = httpService;
                this.setState({
                    keycloak: keycloak, authenticated: authenticated, httpService: httpService,
                });
            });
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(5).then((refreshed) => {
                    if (refreshed) {
                        window.httpService = axios.create({
                            baseURL: apiUrl,
                            headers: {
                                Authorization: "Bearer " + keycloak.token
                            }
                        });
                    }
                }).catch(() => { //impossible de renouveler le token d'auth
                    window.location.reload(); //on refresh la page pour forcer un nouveau token
                })
            }
        }
    }


    render() {
        if (this.state.keycloak) {
            if (this.state.authenticated) return (
                <div className="main-auth">
                    <Navbar bg="light" style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                        <Navbar.Brand>

                            <Link to="/">
                                <img src="https://logos.bde-insa-lyon.fr/bde/bde.svg" alt="BdE"
                                     className="d-inline-block align-middle" style={{height: "3em",}}/>
                            </Link></Navbar.Brand>

                        <h1>Inscriptions VA</h1>

                        <Nav>
                            {/*<Link to="/authenticated/">
                                <Button>Accueil</Button>
                            </Link>*/}
                            <Button variant="outline-secondary" onClick={() => {
                                this.props.history.push('/tour/primo-arrivant'); //dans cet ordre
                                this.state.keycloak.logout();
                            }}>Déconnexion</Button>

                        </Nav>

                    </Navbar>
                    <Route exact path="/authenticated/joinva" render={(props) =>
                        <JoinVA {...props} keycloak={this.state.keycloak} httpService={this.state.httpService}/>}/>
                    <Container style={{marginBottom: 20 + "px"}}>
                        <Route path="/authenticated/member"
                               render={(props) =>
                                   <ProfileComponent {...props} keycloak={this.state.keycloak} httpService={this.state.httpService}/>}/>
                        <Route exact path="/authenticated/" component={Home}/>
                        <Route exact path="/authenticated/biz" component={BizuthFlow}/>
                        <Route exact path="/authenticated/paymentreturn" component={PaymentReturnComponent}/>
                    </Container>
                </div>

            ); else return (
                <div>
                    Unable to authenticate!
                    <Link to="/">retour</Link>
                </div>)
        } else {
            return (
                <div>
                    <Container>
                        <br/><br/><br/><br/>
                        <h6>{allYourMessagesAreBelongToUs[Math.floor(Math.random() * allYourMessagesAreBelongToUs.length)]}</h6>
                        <br/>
                        <Link to="/">retour</Link>&nbsp;
                        <Button variant="secondary" onClick={() => {
                            this.props.history.push('/');
                        }}>déconnexion
                        </Button><br/>
                        <Spinner animation="border"/>
                    </Container>
                </div>

            );
        }
    }
}

export default Authenticator;
