import React, {useEffect} from "react";

export default function PaymentReturnComponent(props) {
    useEffect(() => {
        window.httpService.get('/customer/member/').then((res) => {
            if (res.data.length === 1) {
                let member = res.data[0];
                console.log(member)
                //showError("aaa");
                if (member.category === 'student') {
                    if (member.student_profile.study_year === '1A') {
                        props.history.push('/authenticated/biz' + window.location.search);
                        return;
                    }
                }
                props.history.push('/authenticated/joinva' + window.location.search);
            }
        });
    })
    return <p>Paiement réussi !</p>;
}
