import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import React from "react";
import './landing.css'

export default function Landing(){

    return (
        <div className="vacontainer">
            <h2 className="titleText">Portail adhérent VA INSA Lyon</h2>
            <div className="landing-logos frosted main-landing-box">
                <div>
                    <div className="smallFlex">
                        <img alt="logo BdE" src="https://logos.bde-insa-lyon.fr/bde/bde.svg"
                             className="landing-logo logobde"/>
                        <h1 className="landing-logo smallTitle">Bienvenue !</h1>
                        <img alt="logo VA" src="https://logos.bde-insa-lyon.fr/va/va_color.svg"
                             className="landing-logo logova"/>
                    </div>
                </div>
                <div>
                    <br/>
                    <Link to="/tour/primo-arrivant">
                        <Button size="lg" className="btn btn-danger bouton-rond blink">
                            Découvrir la Vie Associative
                        </Button>
                    </Link>
                </div>
                <div className="boutonsInscriptionConnexionFlex">
                    <Link to="/registration">
                        <Button>&nbsp;S' inscrire&nbsp;&nbsp;</Button>
                    </Link>&nbsp;
                    <Link to="/authenticated/biz" id="loginButton">
                        <Button>Se connecter</Button>
                    </Link>
                </div>
            </div>
        </div>)
}
